import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import MyModal from '../../../components/myModal/MyModal';
import { useTranslation } from 'react-i18next';
import { useForm } from 'antd/es/form/Form';
import * as XLSX from 'xlsx';
import { Button, Form, Radio, Space, Table } from 'antd';
import MyCard from '../../../components/myCard/MyCard';
import UtilNotify from '../../../utils/UtilNotify';
import FormApp from '../../../components/formApp/FormApp';
import InputTextFormItem from '../../../components/myInputForm/InputTextFormItem';
import MySelectFormItem from '../../../components/mySelectFormItem/MySelectFormItem';

const CustomImportModal = forwardRef(({ onClose, onFinish }, ref) => {
  const refModal = useRef(null);

  const { t } = useTranslation();
  const [form] = useForm();

  const [saving, setsaving] = useState(false);
  const [loading, setloading] = useState(false);
  const [step, setstep] = useState(0);
  const [dataFromFile, setdataFromFile] = useState([]);
  const [titleColumns, settitleColumns] = useState([]);
  // const [selectedRow, setselectedRow] = useState(null);

  const selectedRow = Form.useWatch('selectedRow', form);
  // const [open, setOpen] = useState(false);
  // const [visible, setvisible] = useState(false);

  useImperativeHandle(ref, () => ({
    edit: (values) => {
      refModal.current?.open();
      // setOpen(true);
      setstep(0);
      // setselectedRow(null);
      setsaving(false);
      setloading(false);
      form.setFieldsValue(values);

      const file = values.userfile.fileList[0];
      const reader = new FileReader();

      reader.onload = (event) => {
        const arrayBuffer = event.target.result; // ArrayBuffer is loaded
        const data = new Uint8Array(arrayBuffer); // Convert to UInt8Array
        const workbook = XLSX.read(data, { type: 'array' }); // Read workbook from array

        const sheetName = workbook.SheetNames[0]; // Get the first sheet name
        let sheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
          defval: null,
          raw: true,
          header: 1,
          blankrows: false,
        });

        // sheet = sheet.filter((row) =>
        //   row.some((cell) => cell !== null && cell !== undefined && cell !== '')
        // );

        // // Remove empty columns
        // const maxColumnLength = Math.max(...sheet.map((row) => row.length));

        // // Transpose the sheet data (rows to columns and vice versa)
        // let transposedSheet = Array.from({ length: maxColumnLength }, (_, i) =>
        //   sheet.map((row) => row[i] || null)
        // );

        // // Filter out empty columns
        // transposedSheet = transposedSheet.filter((col) =>
        //   col.some((cell) => cell !== null && cell !== undefined && cell !== '')
        // );

        // // Transpose back to original format (columns to rows)
        // sheet = Array.from({ length: transposedSheet[0].length }, (_, i) =>
        //   transposedSheet.map((col) => col[i])
        // );
        setdataFromFile(
          sheet.map((oo, index) => Object.assign(oo, { key: index }))
        );
      };

      reader.onerror = (error) => {
        console.error('Error reading file:', error);
      };

      reader.readAsArrayBuffer(file.originFileObj);
    },
    close: () => {
      refModal.current?.close();
      // setOpen(false);
    },
  }));

  // const parseExcelDate = (excelSerial) => {
  //   const excelEpoch = new Date(1899, 11, 30);
  //   return new Date(excelEpoch.getTime() + excelSerial * 86400000);
  // };

  const columns = [
    {
      // title: t('WORD_INDEX'),
      key: 'Index',
      // dataIndex: 'index',
      width: 20,
      render: (_, __, index) => {
        // return <MyText>{index + 1}</MyText>;
        return index + 1;
      },
    },
    {
      key: 'select',
      // title: 'Select',
      width: 20,
      render: (_, __, index) => (
        <Radio
          checked={selectedRow === index}
          onChange={() => form.setFieldsValue({ selectedRow: index })}
        />
      ),
    },
    ...dataFromFile?.map((col, index) => ({
      title: (
        <div
          style={{
            height: 20,
            maxWidth: 200,
            maxHeight: 20,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {/* {`${index + 1}- ${col}`} */}
          {`${index + 1}`}
        </div>
      ), // Column header from the first row of the sheet
      dataIndex: index, // Corresponds to the row index in each row array
      render: (_, row) => (
        <div
          style={{
            height: 20,
            maxWidth: 200,
            maxHeight: 20,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {row[index]}
        </div>
      ),
    })),
  ];

  const onFinishOptions = (values) => {
    console.log('values :>> ', values);
    setsaving(true);
    refModal?.current?.close();
    onFinish && onFinish(values);
  };

  return (
    <MyModal
      ref={refModal}
      forceRender={false}
      labelOk={t('WORD_APPLY')}
      loading={loading}
      saving={saving}
      onVisibleChange={(e) => !e && onClose && onClose()}
      width={'100%'}
      heigth={'85vh'}
      onOk={() => form.submit()}
      style={{ overflow: 'auto' }}
      footer={(_, { OkBtn, CancelBtn }) => (
        <>
          <Button
            hidden={step <= 0}
            onClick={() => setstep((ov) => ov - 1)}
            loading={saving}
          >
            Prev
          </Button>
          <Button
            type="primary"
            onClick={() => {
              if (
                selectedRow === null ||
                selectedRow === undefined ||
                selectedRow < 0
              ) {
                UtilNotify.notifyError('Please Select the title row');
                return;
              }
              const ll = Object.values(
                dataFromFile[selectedRow].map((oo, index) => {
                  return {
                    label: `${index + 1}- ${oo || ''}`,
                    value: index,
                  };
                })
              );
              settitleColumns(
                // Object.values(dataFromFile[selectedRow]).filter((oo) => oo)
                ll
              );
              form.setFieldsValue({
                listFinalColumns: [
                  {
                    ourField: 'Date',
                    required: true,
                    label: 'Date',
                    // fileField: 0,
                  },
                  {
                    ourField: 'Amount',
                    required: false,
                    label: 'Amount',
                    // fileField: 1,
                  },
                  {
                    ourField: 'Inflow',
                    required: false,
                    label: 'Inflow',
                    // fileField: 3,
                  },
                  {
                    ourField: 'Outflow',
                    required: false,
                    label: 'Outflow',
                    // fileField: 4,
                  },
                  {
                    ourField: 'Note',
                    required: false,
                    label: 'Note',
                    // fileField: 7,
                  },
                  {
                    ourField: 'Category',
                    required: false,
                    label: 'Category',
                    // fileField: 8,
                  },
                  {
                    ourField: 'Payee',
                    required: false,
                    label: 'Payee',
                    // fileField: 8,
                  },
                ],
              });
              setstep((ov) => ov + 1);
            }}
            loading={saving}
            hidden={step === 1}
          >
            Next
          </Button>
          <CancelBtn />
          {step === 1 && <OkBtn />}
        </>
      )}
    >
      <MyCard>
        <FormApp
          name={'formSlectHeaderTwice'}
          form={form}
          onFinish={onFinishOptions}
          // initialValues={{
          //   // bankFileType: 'CUSTOM_FILE_XLS',
          //   // idAccount: 458306,

          // }}
        >
          <InputTextFormItem name={'selectedRow'} hidden={true} />

          {step === 0 && dataFromFile.length > 0 ? (
            <Table
              bordered
              columns={columns}
              dataSource={dataFromFile}
              pagination={false}
              rowKey={(record) => record.key}
            />
          ) : null}
          {step === 1 &&
          (selectedRow || selectedRow === 0) &&
          titleColumns?.length ? (
            <Form.List name="listFinalColumns">
              {(fields) => {
                return (
                  <Space direction="vertical">
                    {fields.map((field, index) => {
                      const indexItem =
                        form.getFieldsValue(true).listFinalColumns[index];
                      const isRequired = indexItem?.required;
                      const label = indexItem?.label;
                      return (
                        <Space key={field.key}>
                          <InputTextFormItem
                            name={[field.name, 'ourField']}
                            readOnly={true}
                            label={index === 0 ? 'Title' : ''}
                            placeholder="Title"
                          />
                          <MySelectFormItem
                            style={{ width: 300 }}
                            label={index === 0 ? 'File title' : ''}
                            name={[field.name, 'fileField']}
                            placeholder={`File title ${label}`}
                            showSearch={false}
                            options={titleColumns}
                            required={isRequired}
                            suffix={'SSS'}
                            allowClear={true}
                          />
                        </Space>
                      );
                    })}
                  </Space>
                );
              }}
            </Form.List>
          ) : null}
        </FormApp>
      </MyCard>
    </MyModal>
  );
});

export default CustomImportModal;
