import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Select, Form, Flex } from 'antd';
import CategoriesActions from '../../../actions/CategoriesActions';
import UtilNotify from '../../../utils/UtilNotify';
import MySpace from '../../mySpace/MySpace';
import MyText from '../../myText/MyText';
import MyButtonSecondary from '../../myButton/MyButtonSecondary';
import GetCategoryIconsWithSize from '../../defaultBudgetCategories/GetCategoryIconsWithSize';
import './SelectCategoryCustom.scss';

const SelectCategoryCustom = ({
  onChange,
  label,
  name,
  required,
  hidden,
  placeholder,
  className,
  onChangeItem,
  allowClear,
  disabled,
  transactionType,
  style,
  errorMessage,
}) => {
  const { t } = useTranslation();

  const [list, setlist] = useState([]);
  const [loading, setloading] = useState(true);
  const [search, setSearch] = useState('');

  const errMessage =
    errorMessage || `${t('WORD_ERROR_PLEASE_SELECT')} ${placeholder || label}!`;

  const fillFS = useCallback(async () => {
    if (t) {
      setloading(true);
      const resultCategory = await CategoriesActions.fillPaginationList({
        search: '',
        filter: {
          // type: CategoriesActions.TYPE.DEFAULT,
          typeIncomeExpense: transactionType,
        },
      });
      if (resultCategory.success) {
        const listParentLevel = resultCategory.data.filter((cat) => {
          // return cat.typeMainChild === CategoriesActions.TYPEMAINCHILD.MAIN;
          return !cat.idParent;
        });
        const listChildrenLevel = resultCategory.data.filter((cat) => {
          return (
            cat.typeMainChild === CategoriesActions.TYPEMAINCHILD.CHILD &&
            cat.idParent
          );
        });
        const data = listParentLevel
          ?.map((cate) => {
            const obj = {
              ...cate,
              key: cate?.idCategory,
              label: cate?.categoryName,
              options: listChildrenLevel
                ?.filter((cat) => cat.idParent === cate.idCategory)
                .map((catc) => {
                  return {
                    ...catc,
                    key: catc.idCategory,
                    value: catc.idCategory,
                    label: catc.categoryName,
                  };
                }),
            };
            return obj;
          })
          .filter(
            (r) =>
              r.typeMainChild === CategoriesActions.TYPEMAINCHILD.CHILD ||
              r.options?.length
          );
        setlist(data);
      } else {
        UtilNotify.notifyErrorServer(resultCategory.errMsg);
      }
      setloading(false);
    }
  }, [t, transactionType]);

  useEffect(() => {
    fillFS();
  }, [fillFS]);

  const handleOnChange = (e, ee) => {
    setSearch('');
    onChange && onChange(e);
    onChangeItem && onChangeItem(ee);
  };

  return (
    <Form.Item
      name={name}
      label={label}
      hidden={hidden}
      className={`select-group-app select_custom category ${className || ''} ${
        loading ? 'loading' : ''
      } ${hidden ? 'hidden' : ''}`}
      rules={[
        { required: required && !hidden, message: errMessage },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value) {
              return Promise.resolve();
            }
            if (getFieldValue(name) !== '') {
              return Promise.resolve();
            }
            return Promise.reject(errorMessage);
          },
        }),
      ]}
    >
      <Select
        style={{ width: '100%', ...style }}
        placeholder={placeholder || label || ''}
        onChange={handleOnChange}
        onSearch={setSearch}
        showSearch={true}
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        optionFilterProp="label"
        loading={loading}
        allowClear={allowClear}
        disabled={disabled}
        notFoundContent={
          search ? (
            <MyButtonSecondary
              onClick={() => {}}
              block
              ocon={<PlusCircleOutlined />}
              label={`Create category`}
              style={{ width: '100%' }}
            />
          ) : (
            'No Data'
          )
        }
      >
        {list.map((parent) =>
          parent.typeMainChild === CategoriesActions.TYPEMAINCHILD.MAIN ? (
            <Select.OptGroup key={parent.key} label={parent.label}>
              {parent?.options?.map((child) => (
                <Select.Option
                  key={child.key}
                  value={child.value}
                  label={child.label}
                >
                  <MySpace fullWidth spaceBetween>
                    <Flex align="center" gap={10}>
                      <GetCategoryIconsWithSize icon={child.icon} size={32} />
                      <MyText>{child.label}</MyText>
                    </Flex>
                  </MySpace>
                </Select.Option>
              ))}
            </Select.OptGroup>
          ) : null
        )}
      </Select>
    </Form.Item>
  );
};

export default SelectCategoryCustom;
