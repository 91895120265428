import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, Form } from 'antd';
import AccountActions from '../../../actions/AccountActions';

const SelectAccountCustom = (props) => {
  const {
    onChange,
    label,
    name,
    required,
    hidden,
    placeholder,
    className,
    onChangeItem,
    disabled,
    allowClear,
    style,
    mode,
  } = props;

  const { t } = useTranslation();

  const [list, setlist] = useState([]);
  const [loading, setloading] = useState(true);
  const [search, setSearch] = useState('');
  const [selected, setselected] = useState();

  const errorMessage = props.errorMessage
    ? props.errorMessage
    : `${t('WORD_ERROR_PLEASE_SELECT')} ${placeholder ? placeholder : label}!`;

  useEffect(() => {
    const fillAccount = async () => {
      setloading(true);
      let resultAccounts = await AccountActions.fillPaginationList({
        search: search,
        pagination: {
          page: 0,
          perPage: 3000,
        },
        filter: {},
      });
      if (resultAccounts.success) {
        let data = [];
        for (const main of AccountActions.defaultAccountTypes) {
          let obj = { ...main };
          obj.label = main.accTyName;
          obj.options = resultAccounts.data
            .filter((it) => {
              return (
                it.idType.toString().startsWith(obj.idGroup) &&
                it.status === AccountActions.STATUS.ACTIVE
              );
            })
            .map((ch) => {
              return { ...ch, label: ch.accountName, value: ch.idAccount };
            });
          if (obj.options.length) {
            const totalAccountValue = obj.options.reduce(
              (previousValue, currentValue) =>
                previousValue + currentValue.currentBalance,
              0
            );
            obj.currentBalance = totalAccountValue;
            data.push(obj);
          }
        }
        setlist(data);
      }
      setloading(false);
    };
    fillAccount();
  }, [t, search]);

  const handleOnChange = (e, ee) => {
    setSearch('');
    setselected(e);
    onChange && onChange(e);
    onChangeItem && onChangeItem(ee);
  };

  const handleSearch = (e) => {
    setSearch(e);
  };

  return (
    <Form.Item
      name={name}
      label={label}
      hidden={hidden}
      className={`select-group-app select_custom account ${className || ''} ${
        loading ? 'loading' : ''
      } ${hidden ? 'hidden' : ''}`}
      rules={[
        { required: required && !hidden, message: errorMessage },
        ({ getFieldValue }) => ({
          validator(rule, value) {
            if (!value) {
              return Promise.resolve();
            }
            if (getFieldValue(name) !== '') {
              return Promise.resolve();
            }
            return Promise.reject(errorMessage);
          },
        }),
      ]}
    >
      <Select
        style={{ width: '100%', ...style }}
        placeholder={placeholder || label || ''}
        onChange={handleOnChange}
        onSearch={handleSearch}
        showSearch
        loading={loading}
        optionFilterProp="label"
        value={selected}
        disabled={disabled}
        allowClear={allowClear}
        options={list}
        mode={mode}
      />
    </Form.Item>
  );
};

export default SelectAccountCustom;
