import React from 'react';
import { Form, Switch, Tooltip } from 'antd';
import Div from '../div/Div';
import './MySwitchFormItem.scss';

const MySwitchFormItemCustom = ({
  label,
  name,
  onChange,
  className,
  disabled,
  toolTipText,
  icon,
}) => {
  return (
    <Form.Item
      name={name}
      valuePropName="checked"
      className={`switch-row custom_switch ${className || ''}`}
    >
      {label && (
        <Div className="switch-label">
          {icon && icon} {label ? label : ''}
          {toolTipText && (
            <Tooltip title={toolTipText}>
              {/* <InfoCircleOutlined
                style={{
                  marginLeft: 6,
                  marginTop: 3,
                }}
              /> */}
            </Tooltip>
          )}
        </Div>
      )}
      <Switch onChange={onChange} className="form-switch" disabled={disabled} />
    </Form.Item>
  );
};

export default MySwitchFormItemCustom;
