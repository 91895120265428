import React from 'react';
import { Flex, Form, Switch, Tooltip } from 'antd';
import Div from '../div/Div';

const MySwitchFormItem = ({
  label,
  name,
  onChange,
  className,
  disabled,
  toolTipText,
  icon,
}) => {
  return (
    <Flex align="center" gap={10}>
      {label && (
        <Div className="switch-label">
          {icon && icon} {label ? label : ''}
          {toolTipText && (
            <Tooltip title={toolTipText}>
              {/* <InfoCircleOutlined
                style={{
                  marginLeft: 6,
                  marginTop: 3,
                }}
              /> */}
            </Tooltip>
          )}
        </Div>
      )}
      <Form.Item
        name={name}
        valuePropName="checked"
        className={`switch-row disableMargin ${className || ''}`}
      >
        <Switch
          onChange={onChange}
          className="form-switch"
          disabled={disabled}
        />
      </Form.Item>
    </Flex>
  );
};

export default MySwitchFormItem;
